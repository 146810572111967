<template>
  <div class="box3 w600 inline-block relative white rounded overflow-hidden box-shadow z-2" :id="'box3_'+No">

      <div class="f-13 f-12-md f-10-xs  f-white mb-6 text-center bold bg-blue py-2 px-2">
        {{ Minds[No].title }}
      </div>
    <div class="bold relative z-2 px-4 pb-4" :id="'box3_text_'+No">
     <div v-html="Minds[No].text" class="text-left"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Minds: Array,
    No: Number,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/parts/box2.scss";
</style>


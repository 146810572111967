<template>
  <div id="order">
    <div id="orderForm" />
    <v-form ref="form" class="w600 relative-center px-2 py-10">
      <div>
        <span class="f-32 f-28-xs noto9 f-black fuchi_shadow"
          >C<span class="f-orange">R</span>EVo </span
        ><br class="dis-xs" />
        <span class="pl-2 bold f-24 f-18-xs">オーダーフォーム</span>
      </div>
      <div class="mt-3">
        以下の項目に入力の上、送信してください<br />
        ※印は必須です
      </div>
      <div class="mt-10 f-12 bold text-left">
        <span class="f-blue">&#9632;</span>ご相談内容
      </div>
      <v-radio-group v-model="subject" mandatory>
        <v-row>
          <v-col cols="6" v-for="(s, i) in subjects" :key="i">
            <v-radio :label="s" :value="s" />
          </v-col>
        </v-row>
      </v-radio-group>
      <v-text-field
        v-model="name"
        :rules="requredRules"
        label="お名前（法人名）*"
        outlined
        type="text"
        maxlength="255"
        class="mt-5"
      />

      <v-text-field
        v-model="zip"
        :rules="zipRules"
        label="郵便番号"
        outlined
        type="text"
        maxlength="8"
        class="zip"
      />
      <v-text-field
        v-model="address"
        label="ご住所*"
        :rules="requredRules"
        type="text"
        outlined
        maxlength="255"
      />

      <v-text-field
        v-model="tel"
        label="お電話番号"
        type="text"
        outlined
        maxlength="15"
        class="tel"
      />

      <v-text-field
        v-model="email"
        outlined
        label="メールアドレス*"
        maxlength="255"
        required
        :error-messages="emailErrors"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      ></v-text-field>
      <div
        class="rounded border_blue mb-5 px-4"
        v-show="subject == '購入の相談' || subject == '査定の相談'"
      >
        <h2 class="f-blue f-12 bold mb-5">物件について</h2>
        <div v-if="subject == '査定の相談'">
          <v-text-field
            v-model="build"
            label="物件名（住所）*"
            type="text"
            outlined
            maxlength="255"
            :rules="requredRules"
          />
          <v-row>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <div class="relative inline-block float-left">
                <v-text-field
                  v-model="roomNum"
                  label="部屋番号"
                  type="text"
                  outlined
                  maxlength="15"
                  class="build"
                />
                <div class="absolute unit">号室</div>
              </div>
              <div class="clear" />
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <div class="relative inline-block float-left">
                <v-text-field
                  v-model="years"
                  label="所有年数*"
                  type="number"
                  outlined
                  maxlength="15"
                  class="build"
                  :rules="moneyRules"
                />
                <div class="absolute unit">年&nbsp;&nbsp;</div>
              </div>
              <div class="clear" />
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <div class="relative inline-block float-left">
                <v-text-field
                  v-model="debt"
                  label="残債*"
                  type="number"
                  outlined
                  maxlength="15"
                  class="build"
                  :rules="moneyRules"
                />
                <div class="absolute unit">円&nbsp;&nbsp;</div>
              </div>
              <div class="clear" />
            </v-col>
          </v-row>

          <div>
            <div class="bold f-12 float-left mx-2">入居状況*</div>
            <v-radio-group v-model="stay" row>
              <v-radio label="有" value="有" />
              <v-radio label="無" value="無" />
            </v-radio-group>
            <div class="clear" />
          </div>
          <div
            class="relative inline-block float-left mt-2"
            v-if="stay == '有'"
          >
            <v-text-field
              v-model="rent"
              label="家賃（管理費込）*"
              type="number"
              outlined
              maxlength="15"
              class="rent"
              :rules="moneyRules"
            />
            <div class="absolute unit">円&nbsp;&nbsp;</div>
          </div>
          <div class="clear" />
        </div>

        <v-row v-show="subject == '購入の相談'">
          <v-radio-group v-model="buy" mandatory class="mb-2">
            <v-row>
              <v-col cols="6" v-for="(b, i) in buies" :key="i">
                <v-radio :label="b" :value="b" class="ml-2" />
              </v-col>
            </v-row>
          </v-radio-group>
        </v-row>
      </div>
      <v-textarea
        v-model="contact"
        :rules="subject == 'その他' ? requredRules : []"
        :label="
          subject == 'その他'
            ? '詳しい内容をご記入ください*'
            : '詳しい内容をご記入ください'
        "
        type="text"
        outlined
        maxlength="1000"
      />

      <v-btn color="#E64A19" class="mt-3 mb-2" dark x-large @click="confirm()">
        送信内容の確認
      </v-btn>
    </v-form>

    <v-dialog width="600" v-model="dialog">
      <v-card class="px-4 pb-4 pt-2 relative">
        <div class="text-right mb-2">
          <v-btn text class="f-blue" @click="dialog = 0">修正する</v-btn>
        </div>
        <h3 class="f-gray mb-6">送信内容の確認</h3>
        <div class="text-left">
          <div>
            <div class="bold mt-3">ご相談内容</div>
            <p class="mt-1">
              {{ subject }}
            </p>
            <hr />
          </div>
          <div>
            <div class="bold mt-3">お名前（法人名）</div>
            <p class="mt-1">
              {{ name }}
            </p>
            <hr />
          </div>
          <div class="bold mt-3">ご住所</div>
          <p class="mt-1">
            <span v-show="zip">〒{{ zip }}<br /></span>
            {{ address }}
          </p>
          <hr />
          <div>
            <div>
              <div v-show="tel">
                <div class="bold mt-3">お電話番号</div>
                <p class="mt-1">
                  {{ tel }}
                </p>
                <hr />
              </div>
              <div class="bold mt-3">メールアドレス</div>
              <p class="mt-1">
                {{ email }}
              </p>
              <hr />
            </div>
            <div v-show="subject == '査定の相談'" class="mb-5">
              <div class="bold mt-3 f-blue text-center">物件について</div>

              <div v-show="build">
                <div class="bold mt-3">物件名（住所）</div>
                <p class="mt-1">
                  {{ build }}
                </p>
                <hr />
              </div>

              <v-row>
                <v-col xl="4" lg="4" md="4" sm="4" cols="12" v-show="roomNum">
                  <div class="bold mt-3">部屋番号</div>
                  <p class="mt-1">{{ roomNum }}号室</p>
                  <hr />
                </v-col>

                <v-col xl="4" lg="4" md="4" sm="4" cols="12" v-show="years">
                  <div class="bold mt-3">所有年数</div>
                  <p class="mt-1">{{ years }}年</p>
                  <hr />
                </v-col>

                <v-col xl="4" lg="4" md="4" sm="4" cols="12" v-show="debt">
                  <div class="bold mt-3">残債</div>
                  <p class="mt-1">{{ Number(debt).toLocaleString() }}円</p>
                  <hr />
                </v-col>
              </v-row>

              <div v-show="stay">
                <div class="bold mt-3">入居状況</div>
                <p class="mt-1">
                  {{ stay }}
                </p>
                <hr />
              </div>

              <div v-show="rent">
                <div class="bold mt-3">家賃（管理費込）</div>
                <p class="mt-1">{{ Number(rent).toLocaleString() }}円</p>
                <hr />
              </div>
            </div>
            <div v-show="subject == '購入の相談'">
              <div class="bold mt-3">物件について</div>
              <p class="mt-1">
                {{ buy }}
              </p>
              <hr />
            </div>
            <div v-show="contact">
              <div class="bold mt-3">詳しい内容をご記入ください</div>
              <p v-text="contact" class="mt-1 nbr" />
              <hr />
            </div>
          </div>
        </div>
        <div class="text-center mt-10">
          <v-btn
            color="#E64A19"
            class="mt-3 mb-2 submit_btn"
            dark
            x-large
            @click="submit()"
            :class="{ noevent: loading == 1 }"
          >
            <v-progress-circular
              v-show="loading == 1"
              indeterminate
              color="white"
            ></v-progress-circular>
            <span v-show="loading == 0">送信する</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <Loading v-show="loading != 0" />
    <Snackbar ref="snack" />
  </div>
</template>

<script>
import Zip from "@/mixins/zip";
import Form from "@/mixins/form";
import Snackbar from "@/components/snackbar.vue";
import Loading from "@/components/loading.vue";
import axios from "axios";
import d from "@/d";
export default {
  mixins: [Zip, Form],
  data() {
    return {
      subjects: ["査定の相談", "売却の相談", "購入の相談", "その他"],
      subject: "査定の相談",
      build: "",
      years: "",
      buies: [
        "候補の物件がある",
        "見学希望の物件がある",
        "物件を探している",
        "まだ何も決まってない",
      ],
      buy: "候補の物件がある",
      dialog: false,
      loading: 0,
      stay: "有",
      rent: "",
      debt: "",
      roomNum: "",
    };
  },
  components: {
    Loading,
    Snackbar,
  },

  async mounted() {
    if (location.hash == "#order") {
      window.location.hash = "orderForm";
      await d.sleep(10);
      window.location.hash = "order";
    }
  },

  methods: {
    async confirm() {
      if (this.stay == "無") {
        this.rent = "";
      }
      this.$refs.form.validate();
      this.$v.$touch();
      if (this.$v.$invalid || !this.$refs.form.validate()) {
        window.location.hash = "orderForm";
        await d.sleep(10);
        window.location.hash = "order";
        return false;
      }
      this.dialog = true;
    },

    async submit() {
      this.loading = 1;

      let data = {};

      data["zip"] = this.zip;
      data["address"] = this.address;
      data["company"] = this.company;
      data["name"] = this.name;
      data["email"] = this.email;
      data["tel"] = this.tel;
      data["contact"] = this.contact;

      data["subject"] = this.subject;
      data["build"] = this.build;
      data["years"] = this.years;
      data["buy"] = this.buy;

      data["roomNum"] = this.roomNum;
      data["debt"] = this.debt;
      data["stay"] = this.stay;
      data["rent"] = this.rent;

      const response = await axios.post("/contact/order_send", data);

      if (response.data.ok) {
        this.$refs.snack.snack = true;
        this.$refs.snack.message = "送信しました";
        this.$refs.form.reset();
        this.$v.$reset();
        this.stay = "有";

        this.build = "";
        this.roomNum = "";
        this.years = "";
        this.debt = "";
        this.rent = "";
      } else {
        alert("エラーが発生しました");
      }
      this.dialog = false;
      this.loading = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/parts/order.scss";
</style>


<template>
  <div>
    <div class="overflow-hidden relative front">
      <div class="h1_top"></div>

      <div class="bg_img w-100 z-1 absolute">
        <div class="bg_img_front w-100 z-2 absolute t-0 l-0" />
      </div>

      <h1 class="z-2 relative-center fuchi">
        <div
          class="mochi f-22 f-20-sm f-19-xs f-14-xxs line20 opacity0 z-5 relative"
          :class="{ 'bottom-up': shows[0] }"
        >
          ご訪問 ありがとうございます
        </div>
        <div class="opacity0" :class="{ 'bottom-up': shows[1] }">
          <span class="noto9 f-23 f-26-sm f-21-xs f-16-xxs"
            >やさがた不動産<span class="pl-1 f-18 f-14-xxs">の</span
            ><br class="dis-xxs" />
            <span class="fuchi_shadow f-26 f-20-xxs">クレボ</span>
          </span>
          <span class="mochi fuchi f-18 f-14-xxs pl-2">です</span>
        </div>
      </h1>

      <v-row
        class="relative z-2 w-100 disno-sm opacity0"
        :class="{ 'fade-in': this.shows[4] }"
      >
        <v-col cols="3" v-for="i in 4" :key="i">
          <div
            class="inline-block py-2 pl-4 pr-3 noto7 f-14 mt-3 text-center f-gray fuchi mark"
            ref="marks"
          >
            {{ marks[i - 1] }}
          </div>
        </v-col>
      </v-row>

      <div class="relative">
        <img
          :src="require('@/assets/img/rogo.png')"
          class="relative-center z-2 filter-shadow opacity0 rogo relative"
          ref="rogo"
        />

        <div
          class="absolute_x front_image inline-block z-2 opacity0 pt-3"
          :class="{ 'fade-in': imgShows[0] }"
        >
          <div class="f-14 f-blue fuchi bold text-center mb-1">
            何をすべきか？
          </div>
          <img src="@/assets/img/front1.svg" />
          <div class="absolute_x f-14 serif fuchi text1">
            かんがえる<br />クレボ
          </div>
        </div>

        <div
          class="absolute_x front_image inline-block z-2 opacity0 pt-3"
          :class="{ 'fade-in': imgShows[1] }"
        >
          <div class="f-14 f-blue fuchi bold text-center mb-1">
            ヨシ、これだ！
          </div>
          <img src="@/assets/img/front2.svg" />
          <div class="absolute_x f-14 serif fuchi text2">
            かんが冴える<br />クレボ
          </div>
        </div>

        <div
          class="absolute_x front_image inline-block z-2 opacity0 pt-3"
          :class="{ 'fade-in': imgShows[2] }"
        >
          <div class="f-14 f-blue fuchi bold text-center mb-1">
            飛び跳ねるぞ！
          </div>
          <img src="@/assets/img/front3.svg" />
          <div class="absolute_x f-14 serif fuchi text3">
            かんがるー<br />クレボ
          </div>
        </div>

        <div
          class="absolute_x front_image inline-block z-2 opacity0 pt-3"
          :class="{ 'fade-in': imgShows[3] }"
        >
          <div class="f-14 f-blue fuchi bold text-center mb-1">
            地域とともに
          </div>
          <img src="@/assets/img/front4.svg" />
          <div class="absolute_x f-14 serif fuchi text4">
            かんがみる<br />クレボ
          </div>
        </div>
      </div>

      <div class="relative-center z-2 yago opacity0" ref="yago">
        <span class="noto9 f-black">
          <span ref="c" class="relative">
            <div class="absolute protain z-1 opacity0 text-center" ref="cre">
              <div class="inline-block text-left">
                <h3 class="f-13">Create：<span class="f-blue">創造</span></h3>
                <div class="f-10 f-normal">
                  新たなビジネスモデルを構築し<br />
                  市場優位性と差別化を図ります
                </div>
              </div>
            </div>
            <span class="fuchi_shadow relative z-2">C</span>
          </span>
          <span class="relative" ref="r">
            <div class="absolute protain z-1 opacity0 text-center" ref="rev">
              <div class="inline-block text-left">
                <h3 class="f-13">Revolt：<span class="f-blue">変革</span></h3>
                <div class="f-10 f-normal">
                  クレボマインドの実践を通じて<br />
                  顧客サービスを向上させます
                </div>
              </div>
            </div>

            <span class="fuchi_shadow relative z-2 f-orange">R</span></span
          >
          <span ref="e" class="relative">
            <div class="absolute protain z-1 opacity0 text-center" ref="evo">
              <div class="inline-block text-left">
                <h3 class="f-13">Evolve：<span class="f-blue">発展</span></h3>
                <div class="f-10 f-normal">
                  誰もが認知し模範とされる<br />
                  地域No.1企業へと躍進します
                </div>
              </div>
            </div>

            <span class="fuchi_shadow relative z-2">E</span></span
          >
          <span ref="v" class="relative">
            <div
              class="absolute protain protain_vo z-1 opacity0 text-center"
              ref="vo"
            >
              <div class="inline-block text-left">
                <h3 class="f-13">
                  Volunteer：<span class="f-blue">奉仕</span>
                </h3>
                <div class="f-10 f-normal">
                  利益やスキルを広く還元し<br />
                  積極的な社会貢献を果たします
                </div>
              </div>
            </div>

            <span class="fuchi_shadow relative z-2">V</span></span
          >
          <span ref="o" class="relative">
            <span class="fuchi_shadow relative z-2">o</span></span
          >
        </span>
      </div>
      <v-row
        class="absolute select z-4 disno-sm opacity0"
        @mouseleave="bgRemove()"
        :class="{ 'fade-in': this.shows[4] }"
      >
        <v-col cols="3" v-for="i in 4" :key="i" @mouseover="sequencer(i)">
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-10 w1000 relative-center" ref="yasagata">
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="4"
        order="2"
        order-xl="1"
        order-lg="1"
        order-md="1"
        order-sm="1"
      >
        <img
          :src="require('@/assets/img/yasagata.jpg')"
          class="rounded-circle yasagata opacity0"
          :class="{ 'bottom-up': shows[2] }"
        />
      </v-col>
      <v-col
        cols="12"
        xl="8"
        lg="8"
        md="8"
        sm="8"
        order="1"
        order-xl="2"
        order-lg="2"
        order-md="2"
        order-sm="2"
        class="text-left"
      >
        <div class="f-24 f-15-sm f-18-xs f-13-xxs f-12-xxxs noto9 mt-5">
          <span class="f-30 f-20-sm f-24-xs f-18-xxs f-blue">Q.</span
          >&nbsp;&nbsp;優形（やさがた）とは？
        </div>
        <div
          class="f-24 f-15-sm f-18-xs f-13-xxs f-12-xxxs noto9 mt-5 opacity0"
          :class="{ 'bottom-up': shows[2] }"
        >
          <span class="f-30 f-20-sm f-24-xs f-18-xxs f-red">A.</span
          >&nbsp;&nbsp;強面（こわもて）の真逆です
        </div>
      </v-col>
    </v-row>
    <div
      class="promise box-shadow w800 relative-center my-10 serif pt-16 pb-16 pl-10 pr-10 relative opacity0"
      :class="{ 'bottom-up': shows[3] }"
      ref="oyakusoku"
    >
      <img
        :src="require('@/assets/img/frame.png')"
        v-for="i in 4"
        :key="i"
        :class="'pa-3 absolute frame frame' + i"
      />
      <div class="relative-center inline-block">
        <div class="f-24 f-20-xxs mb-5">お約束事</div>
        <div
          v-for="(p, i) in promises"
          :key="i"
          class="f-14 f-12-xxs serif my-10 text-left"
        >
          <table>
            <tr>
              <td>{{ i + 1 }}．</td>
              <td>
                {{ p }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div id="company" class="pb-10" />
  </div>
</template>

<script>
import d from "@/d";

export default {
  data() {
    return {
      promises: [
        "商談でなく笑談を。商いに通じなくてもぜんぜん構いません",
        "ゴリ押しや無理強いは一切、行いません",
        "言葉のドッチボールやりません、キャッチボールならば喜んで",
      ],
      shows: [0, 0, 0, 0, 0, 0],
      sc01: 1,
      sc02: 1,
      harp0: 0,
      parl0: 0,
      marks: ["創 造", "変 革", "発 展", "奉 仕"],
      imgShows: [0, 0, 0, 0],
    };
  },
  props: {
    ScroY: Number,
    MenuLoading: Boolean,
    Loading2: Number,
  },

  async mounted() {
    if (window.innerWidth < 960) {
      this.sc01 = 0;
      this.sc02 = 0;
      this.shows = [0, 0, 1, 1];
    }

    while (this.Loading2) {
      await d.sleep(10);
    }
    await d.sleep(500);
    this.shows[0] = 1;
    this.shows.splice();
    await d.sleep(500);
    this.shows[1] = 1;
    this.shows.splice();
    await d.sleep(500);

    this.$refs.yago.classList.add("fade-in");
    this.$refs.rogo.classList.add("rogo_move");
    await d.sleep(500);
    this.shows[4] = 1;
    this.shows.splice();
    await d.sleep(500);
    this.shows[5] = 1;
    this.shows.splice();
  },
  methods: {
    async scroll01() {
      if (
        window.innerHeight + this.ScroY >
          this.$refs.yasagata.getBoundingClientRect().top * 2 &&
        this.sc01
      ) {
        this.sc01 = 0;
        this.shows[2] = 1;
        this.shows.splice();
      }
    },
    async scroll02() {
      if (
        window.innerHeight + this.ScroY >
          this.$refs.oyakusoku.getBoundingClientRect().top * 2.5 &&
        this.sc02
      ) {
        this.sc02 = 0;
        this.shows[3] = 1;
        this.shows.splice();
      }
    },

    async bgRemove() {
      if (window.innerWidth < 960) {
        return false;
      }
      this.$refs.yago.classList.remove("fade-in");
      await d.sleep(10);
      this.squencend();
      this.$refs.yago.classList.add("fade-in");
      this.$refs.rogo.classList.remove("opacity-impact0");
    },
    squencend() {
      for (let i = 0; i < this.$refs.marks.length; i++) {
        this.$refs.marks[i].classList.remove("mark_on");
      }
      this.imgShows = [0, 0, 0, 0];
      this.imgShow = 0;
      this.$refs.c.classList.remove("cre");
      this.$refs.r.classList.remove("cre");
      this.$refs.e.classList.remove("cre");
      this.$refs.cre.classList.remove("fade-in3");

      this.$refs.r.classList.remove("rev");
      this.$refs.e.classList.remove("rev");
      this.$refs.v.classList.remove("rev");

      this.$refs.c.classList.remove("rev_c");
      this.$refs.rev.classList.remove("fade-in3");

      this.$refs.e.classList.remove("evo");
      this.$refs.v.classList.remove("evo");
      this.$refs.o.classList.remove("evo");
      this.$refs.evo.classList.remove("fade-in3");

      this.$refs.v.classList.remove("vo");
      this.$refs.o.classList.remove("vo");
      this.$refs.vo.classList.remove("fade-in3");
    },
    async sequencer(i) {
      if (window.innerWidth < 960 || this.shows[5] == 0) {
        return false;
      }
      this.squencend();
      switch (i) {
        case 1:
          this.$refs.c.classList.add("cre");
          this.$refs.r.classList.add("cre");
          this.$refs.e.classList.add("cre");
          this.$refs.cre.classList.add("fade-in3");
          this.imgShows = [1, 0, 0, 0];
          break;

        case 2:
          this.$refs.r.classList.add("rev");
          this.$refs.e.classList.add("rev");
          this.$refs.v.classList.add("rev");
          this.$refs.c.classList.add("rev_c");
          this.$refs.rev.classList.add("fade-in3");
          this.imgShows = [0, 1, 0, 0];
          break;

        case 3:
          this.$refs.e.classList.add("evo");
          this.$refs.v.classList.add("evo");
          this.$refs.o.classList.add("evo");
          this.$refs.evo.classList.add("fade-in3");
          this.imgShows = [0, 0, 1, 0];
          break;

        case 4:
          this.$refs.v.classList.add("vo");
          this.$refs.o.classList.add("vo");
          this.$refs.vo.classList.add("fade-in3");
          this.imgShows = [0, 0, 0, 1];
          break;
      }
      this.$refs.rogo.classList.add("opacity-impact0");
      this.$refs.marks[i - 1].classList.add("mark_on");
    },
  },
  watch: {
    ScroY() {
      this.scroll01();
      this.scroll02();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/top/front.scss";
</style>


